import React from 'react'
import {ProfilesContainer, ProfilesH1, ProfilesWrapper, ProfilesCard, ProfilesIcon, ProfilesH2, ProfilesP} from './ProfilesElements'
import { SocialIcon } from '../Footer/Footer.elements'
import Nhat from '../../photos/nhat.jpg'
import Trinh from '../../photos/bap.jpg'
import Thao from '../../photos/Thao.jpg'
import Diem from '../../photos/Diem.jpg'
import Dang from '../../photos/Dang.jpg'
import Roselyn from '../../photos/Rose-final.jpg'
import Ngoc from '../../photos/ngoc-2.jpg'
import Long from '../../photos/long.jpg'
import Thu from '../../photos/thu.jpg'
const Profiles = () => {
  return (
    <>
        <ProfilesContainer id="profiles">
            <ProfilesH1>
                <SocialIcon/> 
                OUR TUTORS
            </ProfilesH1>
            <ProfilesWrapper>
                <ProfilesCard>
                    <ProfilesIcon src = {Nhat} />
                    <ProfilesH2>Nhật Đoàn</ProfilesH2>
                    <ProfilesP>
                        I'm Nhat Doan! I graduated from Sac State with a Bachelor's Degree in Computer Science in May of 2021. I have been a tutor in math and computer science for more than 6 years. It is great to see students achieve their goals, and that is my goal to ensure that to happen.
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Ngoc} />
                    <ProfilesH2>Ngọc Nguyễn</ProfilesH2>
                    <ProfilesP>
                        Hello! I’m Kieu (Q) Nguyen, a UC Davis 2023 graduate with a degree in Biochemistry and Molecular Biology. I’ve had the privilege of tutoring students in biology, chemistry, and math, with a passion for making learning fun and meaningful through creative teaching methods.
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Diem} />
                    <ProfilesH2>Diễm Nguyễn</ProfilesH2>
                    <ProfilesP>
                        Hello, I’m Kieu Diem Nguyen, a UC Davis graduate with five years of experience tutoring math and biology. I help students explore various study techniques, allowing them to find the methods that work best for their learning style.
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Thao} />
                    <ProfilesH2>Thảo Nguyễn</ProfilesH2>
                    <ProfilesP>
                        I'm Thảo Nguyễn, a Sacramento State graduate (May 2024) with a double major in Health Science and Nutrition & Food. With two years of tutoring and five years in the Vietnamese Eucharistic Youth Movement, I strive to make learning engaging, build student confidence, and support academic success.
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Trinh} />
                    <ProfilesH2>Trinh Nguyễn</ProfilesH2>
                    <ProfilesP>
                        Hello, my name is Trinh Nguyễn. I graduated from California State University, Sacramento, with a Bachelor's Degree in Public Health in May 2021. I have experience in community health and education, striving to make learning accessible and empowering for students.
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Dang} />
                    <ProfilesH2>Đăng Hà</ProfilesH2>
                    <ProfilesP>
                        I'm Dang Ha, a UC Davis 2023 graduate with a Bachelor's in Biological Sciences. Since 2021, I’ve tutored in biology, math, and English, working with students from all grades. Through tutoring and volunteering with the Vietnamese Eucharistic Youth Movement, I focus on helping students grasp concepts and reach their academic goals.
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Roselyn} />
                    <ProfilesH2>Roselyn Nguyễn</ProfilesH2>
                    <ProfilesP>
                        My name is Rose. I have over 7 years of youth teaching experience through the Vietnamese Eucharistic Youth Movement and a strong background in healthcare, I specialize in creating engaging lessons that help students achieve their academic goals. I look forward to working with you!
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Long} />
                    <ProfilesH2>Long Huỳnh</ProfilesH2>
                    <ProfilesP>
                        I'm Long Huỳnh, a CSU Sacramento 2024 graduate with a double major in Chemistry and Physics. I earned my Single Subject Teaching Credential in 2025 and have tutored since 2019, also teaching martial arts, dance, and at the Vietnamese Eucharistic Youth Movement. I strive to help students understand and enjoy learning.
                    </ProfilesP>
                </ProfilesCard>
                <ProfilesCard>
                    <ProfilesIcon src = {Thu} />
                    <ProfilesH2>Thư Nguyễn</ProfilesH2>
                    <ProfilesP>
                        Hi, I'm Thư Nguyễn! I'm a Biological Sciences major with a concentration in Clinical Laboratory Science at Sacramento State (Class of 2025). With experience as a peer advisor and tutor in math, biology, and chemistry, I strive to make learning material engaging, helping others enjoy the learning process.
                    </ProfilesP>
                </ProfilesCard>
            </ProfilesWrapper>
        </ProfilesContainer>
    </>
  )
}

export default Profiles